import React from 'react';
import api from './api';
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ContactUsDataLoaded } from './actions';
import MetaTags from 'react-meta-tags'
import { Constants } from '../../Constants';
import VirtualZone from '../Header/virtualZone';
import { GAPageView, GARecordEvent } from '../../Utility/GA';
import { withTranslation } from 'react-i18next'
class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
            selectedIndex: this.props.headerMenuItems.findIndex(x => x.href.indexOf('/contact_us') > -1) || -1,
            selectedSubIndex: -1,
            ContactPersons: null,
            ContactForm: null,
            loadingError: '',
            menu: this.props.headerMenuItems.find(x => x.href == (this.props.event.alias + "/contact_us")),
            showComingSoonMsg: false
        };
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language" + this.props.event.alias) || 'en')

    }

    componentDidMount() {
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=contactus').then((response) => {
            // if (response.ok) {
            //     return response.json();
            // }
            console.log("====error in fetch is ===", response)
            //throw new Error('Something went wrong');
        })
        fetch(`/${this.props.event.alias}/contactus`)
        this.setState({ ContactPersons: this.props.contactPersons, ContactForm: this.props.contactForms })
        var count = 0
        if (this.props.contactPersons != null) {
            if (this.props.contactPersons.length == 0) {
                count += 1
            }
        }
        if (this.props.contactForms != null) {
            if (this.props.contactForms.length == 0) {
                count += 1
            }
        }
        if (count == 2) {
            this.setState({ showComingSoonMsg: true })
        }
        if (this.state.selectedIndex == -1) {
            for (var i = 0; i < this.props.headerMenuItems.length; i++) {
                var result = utility.searchTree(this.props.headerMenuItems[i], 'Contact Us')
                if (result != null) {
                    let selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x => x.href.indexOf('/contact_us') > -1)
                    this.setState({ selectedIndex: i, selectedSubIndex })
                    break
                }
            }
        }
        this.count = 0
        if (this.props.contactPersons == null) {
            this.getEventContactPerson()
        }
        if (this.props.contactForms == null) {
            this.getEventContactForm()
        }
        document.addEventListener('navigateToLogin', (event) => {
            this.OnNavigateToPage(this.props.event.alias + "/login")
        })
    }
    getEventContactPerson = () => {
        api.getEventContactPerson(this.state.event.alias, (err, data) => {
            if (err == null) {
                if (data.persons.length == 0) {
                    this.count += 1
                }
                if (this.count == 2) {
                    this.setState({ showComingSoonMsg: true })
                }
                this.props.contactUsLoaded(data.persons, true)
                this.setState({ ContactPersons: data.persons, loadingError: '' });
                return
            }
            if (this.props.contactPersons == null)
                this.setState({ loadingError: 'Something went wrong' })
        })
    }
    getEventContactForm = () => {
        api.getEventContactForm(this.state.event.alias, (err, data) => {
            if (err == null) {
                if (data.forms.length == 0) {
                    this.count += 1
                }
                if (this.count == 2) {
                    this.setState({ showComingSoonMsg: true })
                }
                this.props.contactUsLoaded(data.forms, false)
                this.setState({ ContactForm: data.forms, loadingError: '' });
                return
            }
            if (this.props.contactForms == null)
                this.setState({ loadingError: 'Something went wrong' })
        })
    }
    OnNavigateToPage = (path) => {
        window.scrollTo(0, 0)
        if (path.indexOf('home') > -1) {
            path = "/"
        } else if (path != '/') {
            path = "/" + path
        }
        this.props.history.push(path)
    }
    OnPressRetry = () => {
        this.setState({ loadingError: '' })
        if (this.state.ContactPersons == null) {
            this.getEventContactPerson()
        }
        if (this.state.ContactForm == null) {
            this.getEventContactForm()
        }
    }
    languageClick = (value) => {
        console.log("Trigger contact us on lan select")
        this.props.languageClick(value);
    }

    formSubmittedEvent = (name, email) => {
        GARecordEvent("formSubmitClicked", "not available", {
            category: 'Contact Us',
            action: 'Form submit clicked',
            userName: name,
            userEmail: email
        })
    }
    OnClickAccountItem = (index, path) => {
        this.props.onClickAccountItem(index, path)
        if (index == null)
            return
        if (index > -1) {
            this.props.history.replace('')
            this.props.history.push({
                pathname: this.props.event.alias + '/account',
                state: { tabIndex: index }
            })
        }
        else {
            localStorage.removeItem(Constants.API_TOKEN + this.props.event.alias + 'user_info')
            window.location.reload()
        }
    }
    getClasses = () => {
        if (this.props.event.is_marketplace == "1") {
            let user = utility.getLoggedInUser(this.props.event.alias)
            if (user == null) {
                return 'content-data'
            }
            else if (user != null && user.isBooking == false) {
                return 'content-data'
            }
            else if (user != null && user.isBooking == true && Constants.showVirtualZone == false) {
                return 'content-data'
            }
            else {
                return ''
            }
        }
        else {
            let user = utility.getLoggedInUser(this.props.event.alias)
            if (user == null) {
                return 'content-data'
            }
            // else if(user != null && user.isBooking == false){
            //     return ''
            // }
            else if (user != null && user.isBooking == true && Constants.showVirtualZone == false) {
                return 'content-data'
            }
            else if (user != null && user.isBooking == false && Constants.showVirtualZone == true) {
                return ''
            }
            else if (user != null && user.isBooking == false && Constants.showVirtualZone == false) {
                return 'content-data'
            }
            else {
                return ''
            }
        }
    }
    render() {
        let user = utility.getLoggedInUser(this.props.event.alias)
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.menu != null ? this.state.menu.title : "Contact Us"}</title>
                    <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                    <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                </Helmet>
                <div id="header-top">
                    <Header
                        selectedIndex={this.state.selectedIndex}
                        selectedSubIndex={this.state.selectedSubIndex}
                        onMenuItemClick={this.OnNavigateToPage}
                        elements={this.props.headerMenuItems}
                        facebookLink={this.props.event.facebook}
                        linkedInLink={this.props.event.linkedIn}
                        twitterLink={this.props.event.twitter}
                        instagramLink={this.props.event.instagram}
                        isMarketplace={this.props.event.is_marketplace}
                        event={this.props.event}
                        googleLink={this.props.event.google}
                        phone={this.props.event.phone_number}
                        eventLogo={this.props.event.logo}
                        eventName={this.props.event.name}
                        eventAlias={this.props.event.alias}
                        onLoginClick={() => this.OnNavigateToPage(this.props.event.alias + '/login')}
                        onSignupClick={() => this.OnNavigateToPage(this.props.event.alias + '/sign_up')}
                        onClickAccountItem={this.OnClickAccountItem}
                        availableLocales={this.props.availableLocales}
                        languageClick={this.languageClick}
                    />
                    {user != null && user.isBooking && Constants.showVirtualZone ?
                        <VirtualZone

                            eventAlias={this.props.event.alias}
                            event={this.props.event}
                            onMenuItemClick={(path) => {
                                if (path != '') {
                                    this.OnNavigateToPage(path)
                                }
                            }}
                            unReadCount={this.props.unReadCount}
                            onNotificationSeen={this.props.onNotificationSeen}
                            onClearNotifications={this.props.onClearNotifications}
                            notificationsCount={this.props.unReadNotificationsCount}
                            notifications={this.props.notifications}
                            onClickAccountItem={this.OnClickAccountItem}
                            onClickNotification={(path, isExternal) => {
                                if (isExternal) {
                                    window.open(path, "_blank")
                                } else {
                                    this.OnNavigateToPage(this.props.event.alias + path)
                                }
                            }}
                            menuItems={this.props.virtualMenuItems}
                            user={user}
                        /> : null}
                </div>
                <div className={`content-main`}>
                    {this.state.ContactForm != null && this.state.ContactPersons != null ?
                        <div>
                            {this.state.showComingSoonMsg ?
                                <div className="text-center bg-light">
                                    <img style={{ width: '50%', margin: '50px 0px' }} src="/images/coming-soon.svg" />
                                </div> :
                                <Content
                                    event={this.state.event}
                                    ContactPersons={this.state.ContactPersons}
                                    ContactForm={this.state.ContactForm}
                                    defaultCountry={Constants.defaultCountry}
                                    translation={this.translation}
                                    recordEvent={this.formSubmittedEvent}
                                    useTranslation={this.props.i18n}
                                />}
                            {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ? <NewsLetter
                                title="SUBSCRIBE OUR NEWS LETTER"
                                event={this.state.event}
                                event_alias={this.state.event_alias}
                                buttonText="Subscribe"
                                type='newsLetter'
                            /> : null}
                        </div> :
                        <div style={{ width: '100%', height: window.innerHeight, background: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {this.state.loadingError == "" ?
                                <Loader
                                    type='Oval'
                                    color="#00b6ff"
                                    height={40}
                                    width={40}
                                /> :
                                <ErrorScreen
                                    message={this.state.loadingError}
                                    onPressRetry={this.OnPressRetry}
                                />
                            }
                        </div>
                    }
                    <Footer1
                        event={this.state.event}
                        elements={this.props.footerMenuItems}
                        onMenuItemClick={this.OnNavigateToPage}
                    />
                </div>
                <Footer2
                    event={this.props.event}
                    copyRightLogo={this.props.event.copyright_logo}
                    copyRightText={this.props.event.copyright_text}
                    copyRightLink={this.props.event.copyright_link}
                    showCopyRight={this.props.event.copyright_text || this.props.event.copyright_logo}
                />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    contactPersons: state.contactus.contactPersons,
    contactForms: state.contactus.contactForms
})
const mapDispatchToProps = dispatch => ({
    contactUsLoaded: (data, persons) => dispatch(ContactUsDataLoaded(data, persons))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ContactUs);