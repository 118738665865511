import React from 'react';
import './App.css';
import api from './api';
import Routes from './routes';
import Home from '../Home';
import Loader from 'react-loader-spinner'
import { Helmet } from "react-helmet";
import { ErrorScreen } from '../ErrorScreen';
import axios from '../axios/index';
import $, { data } from 'jquery'
import { Bounce, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'
import { isMobile } from 'react-device-detect'
import { Constants } from '../../Constants';
import queryString from 'query-string'
import ReactGA, { send } from 'react-ga';
import MetaTags from 'react-meta-tags';
import TagManager from 'react-gtm-module'
import utility, { jobBoardNavigator, wordsRestrictor, checkIfAppIsRunningLocally } from '../../Utility/utility';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { UpdateOnlineParticipantsList, UpdateParticipantsList, updateOnlineUser, meetingParticipent } from '../1 - Sub menu components/Participants/actions';
import { UpdateChatList } from '../1 - Sub menu components/Chat/actions';
import io from "socket.io-client";
import { Modal, Button } from 'react-bootstrap';
import inviteApi from '../1 - Sub menu components/Invitation/api'
import participantApi from '../1 - Sub menu components/Participants/api'
import countryCodes from '../../Utility/countryCodes.json'
import overlay from '../../Utility/overlay'
import { GARecordEvent } from '../../Utility/GA';
import { UpdateInvitationsList } from '../1 - Sub menu components/Invitation/actions';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import 'animate.css'
import { CookieBar } from '../Footer/CookieBar';
import { Trans } from 'react-i18next';
import { encrypt } from '../../Utility/Encryption';
import loginApi from '../Login/api'
import { getToken, onMessageListener } from '../Notification/firebase'
import FloatingPlayer from '../Schedule/floaingVideo';
import { FloatingPlayerAdded, OnlineUser, AgendaSessionId, allFeatureData, naviagtionAgneda } from './actions';
import PermiumUserModal from "../modals/premiumUserModal";
import { withRouter } from 'react-router';
import firebase_config from "../Schedule/firebase-configuration";
// import { RemoveScroll } from '../1 - Sub menu components/Virtual Exhibition/actions';
require('bootstrap')
class App extends React.Component {
    retryCount = 0
    maxHeaderMenuItems = 9
    eventAlias = "world-cup"
    landingPage = "/" //optioanl i.e. which page to load when loading from preview link
    constructor(props) {
        super(props)
        this.state = {
            headerMenuItems: [],
            footerMenuItems: [],
            event: null,
            eventAlias: 'world-cup',
            loadingError: '',
            eventCountries: [],
            seoPagesData: [],
            eventNationalities: [],
            participants: [],
            socket: null,
            unReadMessageCount: 0,
            unReadMessages: [],
            userChatList: [],
            showErrorPopup: false,
            popUpErrorHeading: '',
            popUpErrorMessage: '',
            mySocketId: '',
            receivedMessagesWhileAway: [],
            unReadNotificationsCount: 0,
            allNotifications: [],
            ieError: '',
            allInvites: {
                sent: [],
                received: []
            },
            virtualMenuItems: [],
            loadingParticipants: true,
            loadingChatList: false,
            loadingInvites: false,
            allConnectedUsers: 0,
            accountReactivate: "Your Account Deletion Request is in Progress. There is Still time to Change Your Mind.",
            deleteAccountLoading: false
        }
        this.allPages = []
        this.sessionExpired = false
        this.virtualMenuItems = []
        this.siteMapMenus = []
        this.certificateCompletionTime = 0
        this.browser = {}
        this.ipAddress = ""
        this.availableLocales = []
        this.isTokenFound = false
        this.defaultHeaderMenu = []
        this.backgroundInterval = null
    }
    // hideScroll = (value) =>{
    //         this.props.RemoveScroll(value)
    // }
    async componentDidMount() {
        localStorage.removeItem("videoLocale")
        $(window).on('hidden.bs.modal', () => {
            $('#code').modal('hide');
            if (typeof Node === 'function' && Node.prototype) {
                const originalRemoveChild = Node.prototype.removeChild;
                Node.prototype.removeChild = function (child) {
                    if (child.parentNode !== this) {
                        if (console) {
                            console.error('Cannot remove a child from a different parent', child, this);
                        }
                        return child;
                    }
                    return originalRemoveChild.apply(this, arguments);
                }

                const originalInsertBefore = Node.prototype.insertBefore;
                Node.prototype.insertBefore = function (newNode, referenceNode) {
                    if (referenceNode && referenceNode.parentNode !== this) {
                        if (console) {
                            console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
                        }
                        return newNode;
                    }
                    return originalInsertBefore.apply(this, arguments);
                }
            }
            //   this.hideScroll(0)
        });
        $(window).on('shown.bs.modal', () => {
            $('#code').modal('show');
            // this.hideScroll(1)
        });
        window.scrollTo(0, 0)
        let notifications = firebase_config.database().ref("notifications");
        notifications.on("child_added", async (item) => {
            var notification = item.val()
            if (this.state.event) {
                if (notification.event_id == this.state.event.id) {
                    this.setState({
                        allNotifications: [{
                            title: notification.title,
                            body: notification.description,
                            action_url: notification.hasOwnProperty('action_url') ? notification.action_url : null,
                            is_external_link: notification.hasOwnProperty('is_external_link') ? notification.is_external_link : null,
                            time: utility.convertUTCDateToLocalDate(new Date(notification.created_at)),
                            timeString: utility.calculateTimeString(utility.convertUTCDateToLocalDate(new Date(notification.created_at)))

                        }, ...this.state.allNotifications], unReadNotificationsCount: this.state.unReadNotificationsCount + 1
                    })
                }
            }
        })
        if (process.env.REACT_APP_ENV_NAME == "production" || process.env.REACT_APP_ENV_NAME == "Staging") {
            console.log = function () { }
        }
        console.log("====hellooooooooo================================")
        if (process.env.REACT_APP_SOCKET_URL == "") {
            Constants.socketUrl = new URL(window.location.href).origin
        }
        onMessageListener().then(payload => {
            if ('serviceWorker' in navigator) {
                this.notificationReceieved(payload)
            }
        }).catch(err => {
            console.log(err)
        })
        overlay.init()
        this.browser = utility.getBrowserInfo()
        if (utility.isIE()) {
            this.setState({ event: null, ieError: 'We do not support IE please use any of the modern day browser', loadingError: 'We do not support IE please use any of the modern day browser' })
            return
        }
        localStorage.removeItem("participantOnlineToggle")

        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker.addEventListener('message', e => {
        //         console.log(e)
        //         this.notificationReceieved(e.data)
        //     });
        // }
        $(document).ready(function () {
            //Check to see if the window is top if not then display button
            $(window).scroll(function () {
                if (utility.mobileCheck()) {
                    return
                }
                if ($(this).scrollTop() > 200) {
                    $('#s-t-t').fadeIn();
                    $('#s-t-t').removeClass('move-to-top-hidden')
                    $('#s-t-t').addClass('move-to-top')
                } else {
                    $('#s-t-t').removeClass('move-to-top')
                    $('#s-t-t').addClass('move-to-top-hidden')
                    $('#s-t-t').fadeOut();
                }
            });

            //Click event to scroll to top
            $('#s-t-t').click(function () {
                $('html, body').animate({ scrollTop: 0 }, 800);
                return false;
            });

        });
        if (utility.mobileCheck()) {
            document.getElementById("s-t-t").style.display = "none"
        }
        let res = await this.geteventCredentialsURL()
        if (process.env.REACT_APP_ENV_NAME != 'development') {
            this.getSocialLoginIds()
        }
        //if (res) {
        this.getEvent(this.eventAlias)
        //}
        $(document).ready(function () {
            $('#s-t-t').fadeOut();
        })
        var response = ""
        //     document.addEventListener("visibilitychange",()=>{
        //         var isTabActive = document.hidden ? false : true;
        //         if(isTabActive){
        //             clearInterval(this.backgroundInterval)
        //             this.backgroundInterval = null
        //             if(response != ""){
        //                 api.getUserNotifications(this.eventAlias,this.user.token,(err,notifications,unReadNotificationsCount)=>{
        //                     if(err == null){
        //                         this.setState({allNotifications : notifications,unReadNotificationsCount})
        //                     }
        //                 })
        //             }
        //             response = ""
        //         }else{
        //             if(this.user != null && this.user.isBooking){
        //                 this.backgroundInterval = setInterval(() => {
        //                     if(response == ""){
        //                         response = "fetching"
        //                         api.getUserNotifications(this.eventAlias,this.user.token,(err,notifications,unReadNotificationsCount)=>{
        //                             response = ""
        //                             if(err == null){
        //                                 this.setState({allNotifications : notifications,unReadNotificationsCount})
        //                             }
        //                         })
        //                     }
        //                 }, 2000)
        //             }
        //         }
        //     })
    }
    reactiveAccount = async () => {
        this.setState({ deleteAccountLoading: true })
        await axios.post(`${this.state.event.alias}/deleteAccount/reActivate`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization': 'Bearer ' + utility.getLoggedInUser(this.state.event.alias).token
            }
        }
        ).then(response => {
            this.setState({ deleteAccountLoading: false, accountReactivate: "Your Account Reactivate successfully.Please Login again" })
            setTimeout(() => {
                localStorage.removeItem(Constants.API_TOKEN + this.state.event.alias + 'user_info')
                window.location.reload()

            }, 3000)

        }).catch(error => {
            this.setState({ deleteAccountLoading: false })
        })
    }
    getNotification = (title, body) => {
        return <div>
            <p style={{ color: 'black', fontSize: '15px', fontWeight: 'bold', marginBottom: '5PX' }}>{wordsRestrictor(title, 5)}</p>
            <p style={{ color: 'black', fontSize: '12px' }}>{wordsRestrictor(body, 15)}</p>
        </div>
    }
    notificationReceieved = (payload) => {
        let allNotifications = this.state.allNotifications
        var item = payload
        item.title = item.notification.title
        item.body = item.notification.body

        toast.configure();
        toast(this.getNotification(`${item.title}`.replace(null, ''), item.body == undefined ? ' ' : `${item.body}`.replace(null, '')), {
            // onOpen: props => console.log(props.foo),
            // onClose: props => console.log(props.foo),
            autoClose: true,
            // closeButton: FontAwesomeCloseButton,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
            position: toast.POSITION.TOP_RIGHT,
            pauseOnHover: true,
            transition: Zoom,
            progressClassName: 'progress'
            // and so on ...
        });
        // store.addNotification({
        //     title: `${item.title}`.replace(null,''),
        //     message: item.body == undefined ? ' ': `${item.body}`.replace(null,''),
        //     type: "default",
        //     insert: "bottom",
        //     container: "bottom-right",
        //     animationIn: ["animate__animated", "animate__zoomIn"],
        //     animationOut: ["animate__animated", "animate__zoomOut"],
        //     dismiss: {
        //         duration: 5000,
        //         showIcon : true,
        //         click : false,
        //         pauseOnHover : true
        //     }
        // });
        item.timeString = "Now"
        item.id = item.id
        item.action_url = item.action
        if (item.data['gcm.notification.type'] != "announcements") {
            allNotifications.unshift(item)

        }
        if (item.data['gcm.notification.type'] != "announcements") {
            if (item.invitation != null) {
                var invite = item.invitation
                if (invite.status == null) {
                    var sender = this.props.participants.find(x => x.id == item.invitation.sender_id)
                    if (sender != null) {
                        invite.sender_details = {}
                        invite.sender_details.firstName = sender.firstName
                        invite.sender_details.lastName = sender.lastName
                        invite.sender_details.jobTitle = sender.jobTitle
                        invite.sender_details.companyName = sender.companyName
                        invite.sender_details.profileImageUrl = sender.profileImageUrl
                    }
                    invite.status = 0
                    this.OnAddNewInvite(invite, 'receiver')
                }
                else {
                    let allInvites = this.props.allInvites
                    let index = allInvites.sent.findIndex(x => x.id == invite.id)
                    if (index > -1) {
                        allInvites.sent[index].status = invite.status
                    }
                    if (item.data['gcm.notification.type'] != "announcements") {
                        this.props.updateInvitationsList(allInvites)
                    }
                    if (invite.sender_details != null) {
                        const event = new CustomEvent('updateInvites', {
                            detail: { invites: allInvites }
                        })
                        document.dispatchEvent(event)
                    }
                }
            }
            else {
                console.log("Error")
                inviteApi.getInviatations(this.eventAlias, this.user.token, (err, allInvites) => {
                    if (err == null) {
                        console.log(allInvites)
                        this.OnAddNewInvite(allInvites.received[0], 'receiver')
                    }
                })
            }
        }
        console.log(item)
        if (item.data['gcm.notification.type'] != "announcements") {
            this.setState({ unReadNotificationsCount: this.state.unReadNotificationsCount + 1 })
        }
        this.setState({ allNotifications })
    }
    InitiateSocket(user) {
        let socketURL = Constants.socketUrl
        socketURL += "?userId=" + user.id + "&userName=" + (user.firstName + " " + user.lastName) + "&eventAlias=" + this.event.alias
        this.socket = io.connect(socketURL, { forceNew: true, upgrade: false, transports: ['websocket'] })
        console.log("=this.socket.id=", this.socket.id)
        this.socket.on('connect', () => {
            this.setState({ mySocketId: this.socket.id })
        })
        this.socket.on('allConnectedUsers', (users) => {
            let currentEventUser = users.filter(item => item.eventAlias == this.event.alias)

            let participants = this.props.participants
            let onlineParticipantIds = this.props.onlineParicipants
            var onlineParticipants = participants.filter(elem => {
                let status
                let index = currentEventUser.findIndex(x => x.userId == elem.id)
                if (index > -1) {
                    status = true
                    elem.isOnline = true
                    elem.socketId = users[index].socketId
                }
                return status
            })
            currentEventUser.filter(elem => {
                let index = participants.findIndex(x => x.id == elem.userId)
                if (index < 0) {
                    onlineParticipantIds.push({
                        userId: elem.userId,
                        socketId: elem.socketId
                    })
                }
            })
            participants.map(obj => onlineParticipants.find(o => o.id === obj.id) || obj);
            // var participants = this.props.participants
            // let onlineParticipantIds = this.props.onlineParicipants
            // for(var i=0;i<users.length;i++){
            //     if(users[i].eventAlias == this.event.alias)
            //     {
            //         let index = participants.findIndex(x=>x.id == users[i].userId)
            //         if(index > -1){
            //             participants[index].isOnline = true
            //             participants[index].socketId = users[i].socketId
            //         }else{
            //             onlineParticipantIds.push({
            //                 userId : users[i].userId,
            //                 socketId : users[i].socketId
            //             })
            //         }
            //         if(users[i].userId != this.user.id){
            //             this.setState({allConnectedUsers : this.state.allConnectedUsers+1})
            //         }
            //     }
            // }
            this.props.updateOnlineParticipantsList(onlineParticipantIds)
            this.props.updateOnlineUser(currentEventUser)
            this.props.updateParticipantsList(participants)
            this.props.meetingParticipent(participants)
            this.props.OnlineUser(currentEventUser)
            const event = new CustomEvent('usersConnected', {
                detail: { users: currentEventUser }
            })
            document.dispatchEvent(event)
            // console.log('users ',users)
        })
        this.socket.on('userDisconnected', user => {
            if (user == null) {
                return
            }
            if (this.event.alias != user.eventAlias) {
                return
            }
            let participants = this.props.participants
            let onlineParticipantIds = this.props.onlineParicipants
            let index = participants.findIndex(x => x.id == user.userId)
            if (index > -1) {
                participants[index].isOnline = false
            } else {
                index = onlineParticipantIds.findIndex(x => x.userId == user.userId)
                onlineParticipantIds.splice(index, 1)
            }
            const event = new CustomEvent('userDisconnected', {
                detail: { user: user }
            })
            dispatchEvent(event)
            this.setState({ allConnectedUsers: this.state.allConnectedUsers - 1 })
            this.props.updateOnlineParticipantsList(onlineParticipantIds)
            this.props.updateParticipantsList(participants)
            this.props.meetingParticipent(participants)

        })
        this.socket.on('messageReceived', (data) => {
            if (data.eventAlias != this.event.alias) {
                return
            }
            if (data.id != null) {
                return
            }
            if (data.type == "group") {
                return
            }
            if (window.location.href.indexOf('userChat') == -1) {
                let receivedMessagesWhileAway = this.state.receivedMessagesWhileAway
                let index = receivedMessagesWhileAway.findIndex(x => x.id == data.senderId)
                if (index > -1) {
                    receivedMessagesWhileAway[index].count += 1
                } else {
                    receivedMessagesWhileAway.push({
                        id: data.senderId,
                        count: 1
                    })
                }
                let unReadMessages = this.state.unReadMessages
                index = unReadMessages.findIndex(x => x.userId == data.senderId)
                if (index > -1) {
                    unReadMessages[index].count += 1
                }
                this.setState({ unReadMessageCount: this.state.unReadMessageCount + 1, receivedMessagesWhileAway, unReadMessages })
            }
        })
    }
    getAllFeatures = async (alias) => {
        var data = {
            'data': [],
            'loading': true
        }
        this.props.allFeatureData(data)
        await api.getAllFeatures(alias, (err, data) => {
            let response = {
                'data': data,
                'loading': false
            }
            if (err == null) {
                this.props.allFeatureData(response)
            }
        })
    }
    getEvent(alias) {
        console.log('=this.eventAlias=', this.eventAlias)
        api.getEvent(alias, async (err, data) => {
            //console.log("LOCAL StorAGE", localStorage.getItem("language"+alias))
            //console.log("Event",data.event)
            if (err == null) {
                // localStorage.setItem("defaultLocale",data.event.defaultLocale)
                if (!localStorage.getItem("language" + alias)) {
                    localStorage.setItem("language" + data.event.alias, data.event.defaultLocale)
                    window.location.reload()
                }
                this.event = data.event
                let defaultCodeIndex = countryCodes.findIndex(x => x.code.toLocaleLowerCase() == this.event.countryCode)
                if (defaultCodeIndex > -1) {
                    Constants.defaultCountry.code = this.event.countryCode
                    Constants.defaultCountry.dailCode = countryCodes[defaultCodeIndex].dial_code
                }
                api.getClientCountry((country) => {
                    if (country != null) {
                        this.ipAddress = country.ip
                        let countryCode = country.country
                        let codeData = countryCodes.find(x => x.code == countryCode)
                        if (codeData != null) {
                            Constants.defaultCountry = {
                                code: countryCode.toLowerCase(),
                                dailCode: codeData.dial_code
                            }
                            Constants.countryFlag = countryCode.toLowerCase()
                        }
                    }
                })
                let localLocales = [];
                let tempLocales = this.event.availableLocales === null ? [] : this.event.availableLocales.map((obj) => {
                    let localObj = {
                        value: obj.value,
                        name: obj.name,
                        flag: obj.flag
                    }

                    localLocales.push(localObj)
                })
                this.virtualMenuItems = this.event.virtualZoneSettings
                this.availableLocales = localLocales
                if (this.virtualMenuItems.length == 0) {
                    Constants.showVirtualZone = false
                }
                this.certificateCompletionTime = this.event.certificateCompletionTime
                if (this.event.google_analytics != null) {
                    ReactGA.initialize(this.event.google_analytics)
                    ReactGA.pageview(window.location.hostname + window.location.pathname + window.location.search)
                }
                if (this.event.google_tag_manager != null) {
                    const tagManagerArgs = {
                        gtmId: this.event.google_tag_manager
                    }
                    TagManager.initialize(tagManagerArgs)
                }

                this.setState({ loadingError: '' })
                this.user = utility.getLoggedInUser(data.event.alias)
                let isPremiumUser = false
                if (this.user != null) {
                    if (Constants.showVirtualZone) {
                        await this.getUnReadMessageCount(data.event.alias, this.user)
                    }
                    var res = await api.getUserBookingStatus(data.event.alias, this.user.token, (err, status) => {
                        if (err != -1) {
                            this.user.isBooking = status
                            if (status == true) {
                                if (this.user.hasOwnProperty('jobBoardProfileCompleted')) {
                                    this.user.jobBoardProfileCompleted = 1
                                }
                            }
                            if (status) {
                                this.maxHeaderMenuItems = 1000
                            }
                            isPremiumUser = status
                        } else if (err == -1) {
                            if (this.event.is_jobfair == 1) {
                                api.logoutUser(this.user.jobFairJwt)
                            }
                            const event = new Event('sessionExpired')
                            document.dispatchEvent(event)
                            this.setState({ showErrorPopup: true, popUpErrorHeading: 'Login Required', popUpErrorMessage: 'Your session is expired please login again' }, () => {
                                // $("#AppSessionLogin").modal('show');
                            })

                            localStorage.removeItem('login_hash')
                            localStorage.removeItem(Constants.API_TOKEN + this.event.alias + 'user_info')
                        }
                    })
                    localStorage.setItem(Constants.API_TOKEN + data.event.alias + 'user_info', JSON.stringify(this.user))
                    await this.getUserNotifications(data.event.alias, this.user)
                    if (this.event.is_jobfair == 1) {
                        loginApi.insertLoginHashJobFair(this.user.jobFairJwt)
                    }
                }
                await this.getEventMenu(data.event.alias, 'Header', isPremiumUser)
                if (this.user != null && this.user.isBooking && !this.sessionExpired) {
                    this.setState({ loadingChatList: true, loadingInvites: true })
                    this.getUserChatList(data.event.alias, this.user)
                    this.getUserInvites(data.event.alias, this.user)
                }
                if (this.props.allFeature.length == 0) {
                    this.getAllFeatures(data.event.alias)
                }
                await this.getEventMenu(data.event.alias, 'Footer', isPremiumUser)
                this.showCookieBar()
                this.getSeoData(data.event.alias)
                this.getEventCountries(data.event.alias)
                this.getEventParticipants(data.event.alias)
                // axios.post('/generateSiteMaps',
                // {
                //     siteUrl : window.location.protocol+"//"+window.location.host,
                //     routes : this.siteMapMenus
                // },).then(response => {

                // }).catch(error => {

                // })
            }
            else {
                if (err.toString().indexOf('timeout') > -1) {
                    console.log('Retrying ' + this.retryCount + ' times')
                    if (this.retryCount == 4) {
                        this.setState({ loadingError: 'Something went wrong' })
                    }
                    else {
                        this.retryCount += 1
                        this.getEvent(alias)
                    }
                }
                else if (err.toString().indexOf('429') > -1) {
                    this.setState({ loadingError: 'OOPS - We have received too many requests from your IP so for your security we have limited your access for 60 seconds. Please try again.' })
                }
                else {
                    this.setState({ loadingError: err.message })
                }
                let eventString = this.browser.name + ":v" + this.browserVersion + ":os" + this.browser.os + ":ip" + this.ipAddress + ":endPoint/getEvent:error" + JSON.stringify(err)
                GARecordEvent("networkError", this.user ? this.user.id : "anonymous user", {
                    category: 'Error',
                    action: 'Network Error',
                    label: eventString
                })
                let eData = {
                    browserName: this.browser.name,
                    browserVersion: this.browser.version,
                    OSVersion: this.browser.os,
                    ipAddress: this.ipAddress,
                    endPoint: '/getEvent',
                    error: JSON.stringify(err)
                }
                api.saveErrorLog(this.eventAlias, eData, (message) => {

                })
            }
        })
    }
    showCookieBar = () => {
        this.tempCookieBarShown = true
        let showCookieBar = localStorage.getItem(Constants.API_TOKEN + this.eventAlias + 'cookieShown') == "1" ? false : true
        if (window.location.href.indexOf('panelistModeratorView') > 0) {
            showCookieBar = false
        }
        if (!this.sessionExpired && showCookieBar) {
            let rand = Math.floor(Math.random() * 3000) + 500
            setTimeout(() => {

                let cookieBar = document.getElementById('cookieBar')
                if (cookieBar != null && cookieBar != undefined) {
                    cookieBar.classList.add('cookies-up')
                }
            }, rand);
        }
    }
    geteventCredentialsURL = async () => {
        var values = queryString.parse(window.location.search)
        console.log('=== data from url ====', values, "Constants.inPreviewEnv", Constants.inPreviewEnv)
        let ttt = values
        //if (values.isPreview != null && values.isPreview == 1 || Constants.inPreviewEnv || window.location.hostname == "localhost") {
        if (values && values.isPreview != null && values.isPreview == 1 || Constants.inPreviewEnv) {
            Constants.isPreview = 1
        } else {
            Constants.isPreview = 0
        }
        if (values.page != null) {
            this.landingPage = values.page
        }
        console.log("===constants value ==", Constants.isPreview)
        if (Constants.isPreview == 0) {
            const BaseUrl = checkIfAppIsRunningLocally()
            const { data } = await axios.get(`${BaseUrl}/getEventCreds`,
                {
                    headers: {
                        'auth_token': '5{I~hK5&~Nb,rb}'
                    }
                })
            console.log("==data of cren==", data)

            if (data) {
                console.log('===data of response credentials ===', data)
                this.eventAlias = data.eventAlias
                Constants.API_TOKEN = data.apiToken
                this.simpleAlias = this.eventAlias.replace(/%20/g, " ")
            }

            // await fetch('/getEventCreds', {
            //     method: 'GET',
            //     headers: {
            //         'auth_token': '5{I~hK5&~Nb,rb}',
            //     }
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         console.log('===data of response credentials ===', data)
            //         this.eventAlias = data.eventAlias
            //         Constants.API_TOKEN = data.apiToken
            //         this.simpleAlias = this.eventAlias.replace(/%20/g, " ")
            //     })
            localStorage.setItem('alias', this.eventAlias)
            if (values.logout == 1) {
                if (this.user == null) {
                    localStorage.removeItem(Constants.API_TOKEN + this.eventAlias + 'user_info')
                }
            }
            this.queryStringRedirection(values)
            return
        }
        if (window.location.href.indexOf('home') > 0) {
            this.eventAlias = window.location.href.split('/')[3]
        }
        else {
            this.eventAlias = ""
        }
        if (this.eventAlias == "") {
            var storedAlias = localStorage.getItem('alias') || ''
            if (storedAlias != "") {
                this.eventAlias = storedAlias
            }
            else {
                this.eventAlias = 'world-cup'
            }
        }
        this.simpleAlias = this.eventAlias.replace(/%20/g, " ")
        if (values.token != null) {
            Constants.API_TOKEN = values.token
            localStorage.setItem('apiToken', values.token)
        }
        else {
            var storedApiToken = localStorage.getItem('apiToken') || ''
            if (storedApiToken != '') {
                Constants.API_TOKEN = storedApiToken
            }
        }
        // if(!localStorage.getItem('langauge'+this.eventAlias)){
        //     localStorage.setItem("language"+this.eventAlias,'en')
        // }
        localStorage.setItem('alias', this.eventAlias)
        this.queryStringRedirection(values)
    }
    getSocialLoginIds = async () => {
        const BaseUrl = checkIfAppIsRunningLocally()
        const { data } = await axios.get(`${BaseUrl}/getSocialAppIds`,
            {
                headers: {
                    'auth_token': '5{I~hK5&~Nb,rb}'
                }
            })
        console.log("==data of cren==", data)

        if (data) {
            console.log('===data of response getSocialAppIds ===', data)
            Constants.faceBookAppId = data.facebookId
            Constants.googleClientId = data.googleKey
            Constants.linkedInClientId = data.linkedInId
            Constants.linkedInClientSecret = data.linkedInSecret
            Constants.linkedInRedirectUri = data.linkedRecirectUri
            Constants.showVideoCallButton = data.showVideoCallButton
        }
        // fetch('/getSocialAppIds', {
        //     method: 'GET',
        //     headers: {
        //         'auth_token': '5{I~hK5&~Nb,rb}'
        //     }
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         Constants.faceBookAppId = data.facebookId
        //         Constants.googleClientId = data.googleKey
        //         Constants.linkedInClientId = data.linkedInId
        //         Constants.linkedInClientSecret = data.linkedInSecret
        //         Constants.linkedInRedirectUri = data.linkedRecirectUri
        //         Constants.showVideoCallButton = data.showVideoCallButton
        //     }).catch(err => {
        //         console.log('social : ', err)
        //     })
    }
    async getEventMenu(alias, type, isPremiumUser = false) {
        await api.getEventMenu(alias, type, (err, data) => {
            if (err != null) {
                let eventString = this.browser.name + ":v" + this.browserVersion + ":os" + this.browser.os + ":ip" + this.ipAddress + `:endPoint/getEventMenu?type=${type}:error` + JSON.stringify(err)
                // GARecordEvent("networkError" ,this.user.id,{
                //     category : 'Error',
                //     action : 'Network Error',
                //     label : eventString
                // })

                let eData = {
                    browserName: this.browser.name,
                    browserVersion: this.browser.version,
                    OSVersion: this.browser.os,
                    ipAddress: this.ipAddress,
                    endPoint: `/getEventMenu?type=${type}`,
                    error: JSON.stringify(err)
                }
                api.saveErrorLog(alias, eData, (message) => {

                })
                return
            }
            if (data.menu.length == 0) {
                return
            }
            let indexOfAgendaMenu = data.menu[0].menu_list.findIndex(x => x.href == this.simpleAlias + "/agenda")
            if (isPremiumUser) {
                if (indexOfAgendaMenu > -1) {
                    let children = data.menu[0].menu_list[indexOfAgendaMenu].children
                    data.menu[0].menu_list.splice(indexOfAgendaMenu, 1)
                    if (children) {
                        data.menu[0].menu_list = data.menu[0].menu_list.concat(children)
                    }
                } else {
                    for (var i = 0; i < data.menu[0].menu_list.length; i++) {
                        var item = data.menu[0].menu_list[i]
                        if (item.children != null) {
                            indexOfAgendaMenu = item.children.findIndex(x => x.href == this.simpleAlias + "/agenda")
                            if (indexOfAgendaMenu > -1) {
                                let children = item.children[indexOfAgendaMenu].children
                                item.children.splice(indexOfAgendaMenu, 1)
                                if (children) {
                                    data.menu[0].menu_list = data.menu[0].menu_list.concat(children)
                                }
                                break
                            }
                            for (var j = 0; j < item.children.length; j++) {
                                if (item.children[j].children != null) {
                                    indexOfAgendaMenu = item.children[j].children.findIndex(x => x.href == this.simpleAlias + "/agenda")
                                    if (indexOfAgendaMenu > -1) {
                                        let children = item.children[j].children[indexOfAgendaMenu].children
                                        item.children[j].children.splice(indexOfAgendaMenu, 1)
                                        if (children) {
                                            data.menu[0].menu_list = data.menu[0].menu_list.concat(children)
                                        }
                                        break
                                    }
                                }
                            }
                        }
                    }

                }
            }
            var menu = data.menu[0].menu_list
            for (var i = 0; i < menu.length; i++) {
                var index = this.allPages.findIndex(x => x.href == menu[i].href)
                if (index < 0) {
                    this.allPages.push(menu[i])
                }
            }
            let allMenus = data.menu[0].menu_list
            for (var i = 0; i < allMenus.length; i++) {
                if (this.siteMapMenus.findIndex(x => x.path == allMenus[i].href) < 0) {
                    var menuItem = {
                        path: allMenus[i].href
                    }
                    this.siteMapMenus.push(menuItem)
                }
                if (allMenus[i].children != null) {
                    for (var j = 0; j < allMenus[i].children.length; j++) {
                        if (this.siteMapMenus.findIndex(x => x.path == allMenus[i].children[j].href) < 0) {
                            menuItem = {
                                path: allMenus[i].children[j].href
                            }
                            this.siteMapMenus.push(menuItem)
                        }
                        if (allMenus[i].children[j].children != null) {
                            for (var k = 0; k < allMenus[i].children[j].children.length; k++) {
                                if (this.siteMapMenus.findIndex(x => x.path == allMenus[i].children[j].children[k].href) < 0) {
                                    menuItem = {
                                        path: allMenus[i].children[j].children[k].href
                                    }
                                    this.siteMapMenus.push(menuItem)
                                }
                            }
                        }
                    }

                }
            }
            for (var i = 0; i < this.siteMapMenus.length; i++) {
                this.siteMapMenus[i].path = this.siteMapMenus[i].path.replace(this.simpleAlias, this.eventAlias)
            }
            if (type == 'Header') {
                this.defaultHeaderMenu = [...menu]
                var moreItem = {
                    href: "world-cup/more",
                    page_id: "00",
                    page_type: "default_page",
                    target: "_self",
                    text: <Trans>more</Trans>,
                    title: "More",
                    children: []
                }
                if (!isMobile) {
                    if (menu.length > this.maxHeaderMenuItems - 1) {
                        for (var i = 0; i < menu.length; i++) {
                            if (i > this.maxHeaderMenuItems - 2) {
                                moreItem.children.push(menu[i])
                            }
                        }
                        menu.splice(this.maxHeaderMenuItems - 1, menu.length)
                        menu.push(moreItem)
                    }
                }
                else {
                    var finalMenu = []
                    for (var i = 0; i < menu.length; i++) {
                        finalMenu.push(menu[i])
                        if (menu[i].children != null) {
                            for (var j = 0; j < menu[i].children.length; j++) {
                                finalMenu.push(menu[i].children[j])
                                if (menu[i].children[j].children != null) {
                                    for (var k = 0; k < menu[i].children[j].children.length; k++) {
                                        finalMenu.push(menu[i].children[j].children[k])
                                    }
                                }
                            }

                        }
                        // menu = finalMenu
                    }
                    menu = finalMenu
                }

                this.setState({ headerMenuItems: menu })
            }
            else if (type == 'Footer') {
                this.setState({ footerMenuItems: data.menu[0].menu_list })
            }
            this.setState({ event: this.event, virtualMenuItems: this.virtualMenuItems }, () => {
                if (window.fcWidget) {
                    window.fcWidget.setConfig({
                        "headerProperty": {
                            "hideChatButton": false
                        }
                    })
                }
            })
        })
    }
    getEventCountries(alias) {
        api.getEventCountries(alias, async (err, data) => {
            if (err == null) {
                var countries = []
                var nationalities = []
                for (var i = 0; i < data.country.length; i++) {
                    countries.push(data.country[i].country)
                    //nationalities.push(data.country[i].nationality)
                    nationalities.push(data.country[i].country)
                }
                this.setState({ eventCountries: countries, eventNationalities: nationalities })
            }
        })
    }
    async getEventParticipants(alias) {
        if (this.sessionExpired) {
            const event = new CustomEvent('participantsLoaded', {
                detail: { participants: [] }
            })
            document.dispatchEvent(event)
        }
        let user = utility.getLoggedInUser(alias)
        participantApi.getEventParticipants(alias, (list) => {
            if (user != null) {
                list = list.filter(x => x.id != user.id)
            }
            this.props.updateParticipantsList(list)
            this.props.meetingParticipent(list)
            if (user != null && user.isBooking && !this.sessionExpired) {
                this.InitiateSocket(user)
            }
            const event = new CustomEvent('participantsLoaded', {
                detail: { participants: !this.sessionExpired ? list : [] }
            })
            document.dispatchEvent(event)
            this.setState({ loadingParticipants: false })
        })
    }
    async getUnReadMessageCount(alias, user) {
        await api.getUnReadMessages(alias, user.token, (totalCount, unReadMessages) => {
            if (totalCount == -1) {
                localStorage.removeItem(Constants.API_TOKEN + this.event.alias + 'user_info')
                this.sessionExpired = true
            } else {
                this.sessionExpired = false
                this.setState({ unReadMessageCount: totalCount, unReadMessages: unReadMessages })
            }
        })
    }
    async getUserChatList(alias, user) {
        const event = new CustomEvent('chatListLoadingStarted')
        document.dispatchEvent(event)
        api.getUserChatList(alias, user.token, (list) => {
            this.props.updateChatList(list)
            this.setState({ loadingChatList: false })
            const event = new CustomEvent('chatListLoaded', {
                detail: { chatList: !this.sessionExpired ? list : [] }
            })
            document.dispatchEvent(event)
        })
    }
    async getUserNotifications(alias, user) {
        await api.getUserNotifications(alias, user.token, (err, notifications, unReadNotificationsCount) => {
            if (err == null) {
                this.setState({ allNotifications: notifications, unReadNotificationsCount })
            }
        })
    }
    async getUserInvites(alias, user) {
        const event = new CustomEvent('invitesLoadingStarted')
        document.dispatchEvent(event)
        inviteApi.getInviatations(alias, user.token, (err, allInvites) => {
            if (err == null) {
                this.props.updateInvitationsList(allInvites)
                this.setState({ loadingInvites: false })
                const event = new CustomEvent('invitesLoaded', {
                    detail: { allInvites: !this.sessionExpired ? allInvites : { sent: [], received: [] } }
                })
                document.dispatchEvent(event)
            }
        })
    }
    getSeoData(alias) {
        api.getSeoData(alias, async (err, data) => {
            if (err == null) {
                for (var item in data) {
                    var keyWordString = ""
                    for (var i = 0; i < data[item].keywords.length; i++) {
                        keyWordString += data[item].keywords[i]
                        if (i < data[item].keywords.length - 1) {
                            keyWordString += ','
                        }
                    }
                    data[item].keyWordString = keyWordString
                }
                this.setState({ seoPagesData: data })
            }
        })
    }
    OnPressRetry = () => {
        this.retryCount = 0
        this.setState({ loadingError: '' })
        if (this.event == null) {
            this.getEvent(this.eventAlias)
        }
        if (this.state.headerMenuItems == null) {
            this.getEventMenu(this.eventAlias, 'Header')
        }
        if (this.state.footerMenuItems == null) {
            this.getEventMenu(this.eventAlias, 'Footer')
        }
    }
    OnUserLoggedIn = (user, unReadMessageCount, unReadMessages, userChatList, allNotifications, unReadNotificationsCount, allInvites) => {
        this.user = user
        this.props.updateChatList(userChatList)
        this.props.updateInvitationsList(allInvites)
        this.setState({ unReadMessageCount, unReadMessages, allNotifications, unReadNotificationsCount })
        if (Constants.showVirtualZone && user.isBooking) {
            console.log(this.defaultHeaderMenu)
            this.setState({ headerMenuItems: this.defaultHeaderMenu })
            this.InitiateSocket(user)
            this.UpdateHeaderFooterMenu()
        }
        if (!this.tempCookieBarShown) {
            this.showCookieBar()
        }
        getToken(this.tokenFound)
    }
    tokenFound = (val, token) => {
        this.isTokenFound = val
        if (token) {
            var data = {
                user_id: this.user.id,
                deviceToken: token
            }
            api.saveFCMToken(this.eventAlias, data)
        }
    }
    OnClickAccountItem = (index, path) => {
        let user = utility.getLoggedInUser(this.eventAlias)
        if (index == null) {
            let hash = localStorage.getItem('login_hash')
            if (hash) {

                jobBoardNavigator(process.env.REACT_APP_JOBFAIR_REDIRECT_URL, process.env.REACT_APP_JOBFAIR_REDIRECT_URL, this.eventAlias, '_self', user, path)
                // let selectedLocale = localStorage.getItem("language"+this.eventAlias) || 'en';
                // let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
                // +window.location.origin+"&alias="+this.eventAlias+"&locale="+selectedLocale 

            } else {
                // jobBoardNavigator(process.env.REACT_APP_JOBFAIR_REDIRECT_URL,process.env.REACT_APP_JOBFAIR_REDIRECT_URL,this.eventAlias,'_self',user)
                window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL
            }
            // : window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
            // +window.location.origin+"&alias="+this.eventAlias+"?route="+path

        } else if (index == -1) {
            if (this.event.is_jobfair == "1") {
                // let user = utility.getLoggedInUser(this.eventAlias)
                localStorage.removeItem('login_hash')
                api.logoutUser(user.jobFairJwt)
            }

        }
    }
    onClickLanguage = (langValue) => {
        // console.log("LANGUAGE", langValue)
        // let langKey = "language"+localStorage.getItem("event");
        localStorage.setItem("language" + this.eventAlias, langValue);
        window.location.reload();
    }
    UpdateHeaderFooterMenu = () => {
        let headerMenuItems = this.state.headerMenuItems
        let agendItemIndex = headerMenuItems.findIndex(x => x.href == this.simpleAlias + "/agenda")
        if (agendItemIndex > -1) {
            let children = headerMenuItems[agendItemIndex].children
            headerMenuItems.splice(agendItemIndex, 1)
            if (children) {
                headerMenuItems = headerMenuItems.concat(children)
            }
            this.setState({ headerMenuItems })
        } else {
            for (var i = 0; i < headerMenuItems.length; i++) {
                let item = headerMenuItems[i]
                if (item.children != null) {
                    agendItemIndex = item.children.findIndex(x => x.href == this.simpleAlias + "/agenda")
                    if (agendItemIndex > -1) {
                        let children = item.children[agendItemIndex].children
                        item.children.splice(agendItemIndex, 1)
                        if (children) {
                            headerMenuItems = headerMenuItems.concat(children)
                        }
                        this.setState({ headerMenuItems })
                        break
                    }
                    for (var j = 0; j < item.children.length; j++) {
                        if (item.children[j].children != null) {
                            agendItemIndex = item.children[j].children.findIndex(x => x.href == this.simpleAlias + "/agenda")
                            if (agendItemIndex > -1) {
                                let children = item.children[j].children[agendItemIndex].children
                                item.children[j].children.splice(agendItemIndex, 1)
                                if (children) {
                                    headerMenuItems = headerMenuItems.concat(children)
                                }
                                this.setState({ headerMenuItems })
                                break
                            }
                        }
                    }
                }
            }
        }
        let footerMenuItems = this.state.footerMenuItems
        agendItemIndex = footerMenuItems.findIndex(x => x.href == this.eventAlias + "/agenda")
        if (agendItemIndex > -1) {
            footerMenuItems.splice(agendItemIndex, 1)
            this.setState({ footerMenuItems })
        }
    }
    handleClosePopup = () => {
        this.setState({ showErrorPopup: false })
        localStorage.removeItem(Constants.API_TOKEN + this.event.alias + 'user_info')
        const event = new Event('navigateToLogin')
        document.dispatchEvent(event)
    }
    OnAddNewInvite = (invite, type) => {
        let allInvites = this.props.allInvites
        if (type == 'sender') {
            for (var i = 0; i < invite.length; i++) {
                allInvites.sent.unshift(invite[i])
            }
        } else {
            allInvites.received.unshift(invite)
            const event = new CustomEvent('updateInvites', {
                detail: { invites: allInvites }
            })
            document.dispatchEvent(event)
        }
        this.props.updateInvitationsList(allInvites)
    }
    OnNavigateToPage = (path, data = null) => {
        window.scrollTo(0, 0)
        if (path.indexOf('home') > -1) {
            path = "/"
        } else if (path != '/') {
            path = "/" + path
        }
        if (data == null) {
            this.props.history.push(path)
        } else {
            this.props.history.push({
                pathname: path,
                state: data
            })
        }
    }
    queryStringRedirection(values) {
        if (this.user == null) {
            if (values.logout == 1) {
                localStorage.removeItem(Constants.API_TOKEN + this.eventAlias + 'user_info')
            } else if (values.login == 1) {
                window.location.href = "/" + this.eventAlias + "/login"
            } else if (values.signup == 1) {
                window.location.href = "/" + this.eventAlias + "/sign_up"
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.showScroll != prevProps.showScroll) {
            if (this.props.showScroll == 0) {
                // document.body.classList.add('block')
                let scrollBlocker = document.getElementById('scroll-blocker')
                if (scrollBlocker) {
                    scrollBlocker.remove()
                }
            } else {
                // if(document.body.classList.contains('block')){
                //     document.body.classList.remove('block')
                // }
                // document.getElementById('scroll-blocker').remove()
            }
        }
    }
    render() {
        return (
            this.state.event != null ?
                <div>
                    {this.state.event.is_marketplace == "1" ? <link rel="stylesheet" href="/css/telehealth-theme.css" /> : ""

                    }
                    {
                        this.props.showScroll == 1 ? <link id="scroll-blocker" href="/css/index.css" rel="stylesheet"></link> : ""
                    }
                    <link rel="stylesheet" href={this.state.event.theme_css} />
                    {this.state.event.is_marketplace == "1" ? "" : <link rel="stylesheet" href={this.state.event.theme_button} />}
                    <Helmet>
                        <title>{this.state.event.name}</title>
                        {/* <script src="js/mdtimepicker.js"></script>
                        <script src="https://cdn.jsdelivr.net/npm/flatpickr"></script>
                        <link href="css/mdtimepicker.css" rel="stylesheet"/>
                        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"/>
                        <link rel="stylesheet" type="text/css" href="https://npmcdn.com/flatpickr/dist/themes/airbnb.css"></link> */}
                        {/* <link rel="shortcut icon" href = "https://www.menatelehealth.com/favicon.ico" /> */}
                    </Helmet>
                    <ReactNotification />
                    <FloatingPlayer
                        link={this.props.floatingPlayerLink}
                        onClickClose={() => {
                            this.props.updateFloatingPlayerLink("")
                            // localStorage.removeItem("SessionLocalStore")
                            // this.props.naviagtionAgneda(false)
                        }}
                        openAgenda={() => {
                            if (window.location.toString().split('/').includes('agenda')) {
                                this.props.naviagtionAgneda(true)
                                this.props.updateFloatingPlayerLink("")
                            }
                            else {
                                let floating_btn = document.getElementById("float-btn")
                                if (floating_btn) {
                                    floating_btn.click()
                                    localStorage.removeItem("SessionLocalStore")
                                    this.props.naviagtionAgneda(false)
                                }
                            }
                        }}
                        navigateToAgenda={this.props.AgendaSessionId}
                        agendaSlug={this.props.agendaSlug}
                        naviagtionAgneda={this.props.naviagtionAgneda}


                    />
                    <Routes
                        event={this.state.event}
                        FeaturesLoading={this.state.FeaturesLoading}
                        EventBlogs={this.state.EventBlogs}
                        Eventgallery={this.state.Eventgallery}
                        EventTestimonial={this.state.EventTestimonial}
                        EventCounter={this.state.EventCounter}
                        EventHighlight={this.state.EventHighlight}
                        countries={this.state.eventCountries}
                        nationalities={this.state.eventNationalities}
                        headerMenuItems={this.state.headerMenuItems}
                        footerMenuItems={this.state.footerMenuItems}
                        socket={this.socket}
                        allPages={this.allPages}
                        landingPage={this.landingPage}
                        mySocketId={this.state.mySocketId}
                        unReadMessageCount={this.state.unReadMessageCount}
                        unReadMessages={this.state.unReadMessages}
                        loadingParticipants={this.state.loadingParticipants}
                        loadingChatList={this.state.loadingChatList}
                        loadingInvites={this.state.loadingInvites}
                        receivedMessagesWhileAway={this.state.receivedMessagesWhileAway}
                        seoDefaultData={this.state.seoPagesData}
                        unReadNotificationsCount={this.state.unReadNotificationsCount}
                        allNotifications={this.state.allNotifications}
                        virtualMenuItems={this.state.virtualMenuItems}
                        availableLocales={this.availableLocales}
                        certificateCompletionTime={this.certificateCompletionTime}
                        onlineUsersCount={this.state.allConnectedUsers}
                        languageClick={this.onClickLanguage}
                        onChatListUpdated={() => {
                            this.setState({ receivedMessagesWhileAway: [] })
                        }}
                        onMarkChatRead={(count, id) => {
                            let unReadMessages = this.state.unReadMessages
                            var index = unReadMessages.findIndex(x => x.userId == id)
                            if (index > -1) {
                                unReadMessages[index].count = 0
                            }
                            let receivedMessagesWhileAway = this.state.receivedMessagesWhileAway
                            index = receivedMessagesWhileAway.findIndex(x => x.id == id)
                            if (index > -1) {
                                // receivedMessagesWhileAway[index].count = 0
                                receivedMessagesWhileAway.splice(index, 1)
                            }
                            this.setState({ unReadMessageCount: this.state.unReadMessageCount - count, receivedMessagesWhileAway, unReadMessages })
                        }}
                        onUpdateChatList={(item, unReadCount = 0) => {
                            let unReadMessages = this.state.unReadMessages
                            if (unReadCount > 0) {
                                let index = unReadMessages.findIndex(x => x.userId == item.id)
                                if (index > -1) {
                                    unReadMessages[index].count = unReadCount
                                }
                            }
                            if (unReadCount > 0) {
                                this.setState({ unReadMessageCount: this.state.unReadMessageCount + 1 })
                            }
                            let receivedMessagesWhileAway = this.state.receivedMessagesWhileAway
                            let index = receivedMessagesWhileAway.findIndex(x => x.id == item.id)
                            if (index > -1) {
                                receivedMessagesWhileAway.splice(index, 1)
                            }
                            this.setState({ unReadMessages, receivedMessagesWhileAway })
                        }}
                        onNotificationSeen={() => {
                            this.setState({ unReadNotificationsCount: 0 })
                            let allNotifications = this.state.allNotifications
                            for (var i = 0; i < allNotifications.length; i++) {
                                allNotifications[i].read_at = new Date()
                            }
                            this.setState({ allNotifications })
                            let ids = allNotifications.map(x => x.id)
                            var data = {
                                ids: ids
                            }
                            api.markNotificationsRead(this.event.alias, data, this.user.token, (err, message) => {

                            })
                        }}
                        onClearNotifications={(notificationsToRemove = [], optionalIndex = -1) => {
                            let allNotifications = this.state.allNotifications
                            let notificationIdsToDelete = []
                            if (notificationsToRemove.length == 0) {
                                notificationIdsToDelete = allNotifications.map(x => x.id)
                                allNotifications = []
                            } else {
                                notificationIdsToDelete = notificationsToRemove
                                let index = allNotifications.findIndex(x => x.id == notificationsToRemove[0])
                                if (index < 0) {
                                    index = optionalIndex
                                }
                                allNotifications.splice(index, 1)
                            }
                            var data = {
                                ids: notificationIdsToDelete
                            }
                            api.deleUserNotification(this.event.alias, data, this.user.token, (err, message) => {

                            })
                            this.setState({ allNotifications })
                        }}
                        onFreeBookingSuccess={(showVirtualZone) => {
                            if (showVirtualZone) {
                                this.UpdateHeaderFooterMenu()
                            }
                        }}
                        onResetUnReadCount={() => {
                            this.setState({ unReadMessageCount: 0 })
                        }}
                        onAddNewInvite={this.OnAddNewInvite}
                        onUserLoginSuccess={this.OnUserLoggedIn}
                        onClickAccountItem={this.OnClickAccountItem}
                    />
                    <CookieBar
                        onClickClose={() => {
                            let cookieBar = document.getElementById('cookieBar')
                            cookieBar.classList.remove('cookies-up')
                            localStorage.setItem(Constants.API_TOKEN + this.eventAlias + 'cookieShown', "1")
                        }}
                    />
                    {this.user != null && this.user.account_deleted == "1" ? <div class=" accountWarning2 alert alert-warning flex" style={{
                        display: 'flex'
                    }} role="alert">
                        {this.state.accountReactivate}
                        {this.state.deleteAccountLoading == true ? <div> <Loader
                            type='Oval'
                            color="#00b6ff"
                            height={25}
                            width={25}
                        /></div> : null}
                        <button className='btn btn-warning' onClick={() => {
                            //  this.OnNavigateToPage(`${this.props.eventAlias}/account`)
                            this.reactiveAccount()
                        }}>Reactive</button>
                    </div> : null}
                    <PermiumUserModal
                        id="AppSessionLogin"
                        iconClass="fa-lock"
                        title='Login Required'
                        description='Your session is expired please login again'
                        buttonText="Ok"
                        backdrop='static'
                        onClose={() => {
                            $("#AppSessionLogin").modal("hide");
                            $(".modal-backdrop").remove();
                        }}
                        keyboard={false}
                        showButton={true}
                        onClickBookNow={() => {
                            this.handleClosePopup()
                            $("#AppSessionLogin").modal("hide");
                            $(".modal-backdrop").remove();
                            //this.OnNavigateToPage(`${this.event.alias}/login`);
                        }} />
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.showErrorPopup}
                        onHide={this.handleClosePopup}
                        className="loginModal"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                <h1><i class={"fas fa-lock"}></i></h1>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <h3>{this.state.popUpErrorHeading}</h3>
                                <br />
                                <h5> {this.state.popUpErrorMessage}</h5>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="telehealth-btn2" onClick={this.handleClosePopup}>
                                <Trans>login</Trans>
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> :
                <div style={{ width: '100%', height: window.innerHeight, background: '#f7f7f7', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.loadingError == '' ?
                        // <div style = {{display : 'flex',flexDirection : 'column',alignItems : 'center',justifyContent : 'center'}}>
                        //     <Loader
                        //         type = 'BallTriangle'
                        //         color = '#20002E'
                        //     />
                        //     <div style = {{marginTop : 30,fontWeight : 500,fontFamily : 'Monaco',marginLeft : 10}}>
                        //         Loading Event ..
                        //     </div>
                        // </div>
                        <div class="loader-body">
                            <div class="container">
                                <div class="txt">
                                    Loading
                                </div>
                            </div>
                            <div class="loader">
                                <div class="loading">
                                </div>
                            </div>
                        </div>
                        :
                        this.state.ieError == "" ?
                            <ErrorScreen
                                message={this.state.loadingError}
                                onPressRetry={this.OnPressRetry}
                            /> :
                            <div>
                                {this.state.ieError}
                            </div>}
                </div>
        );
    }

}
const mapStateToProps = state => (
    {
        showScroll: state.virtualExhibition.showScroll,
        participants: state.participants.participantsList,
        userChatList: state.chat.chatList,
        allInvites: state.invitations.allInvitations,
        onlineParicipants: state.participants.onlineParticipantIds,
        floatingPlayerLink: state.appData.floatingPlayerLink,
        naviagtionValue: state.appData.naviagtionValue,
        agendaSlug: state.appData.slug,
        allFeature: state.appData.allFeature
    })
const mapDispatchToProps = dispatch => ({
    updateParticipantsList: data => dispatch(UpdateParticipantsList(data)),
    meetingParticipent: data => dispatch(meetingParticipent(data)),
    updateChatList: data => dispatch(UpdateChatList(data)),
    updateInvitationsList: data => dispatch(UpdateInvitationsList(data)),
    updateOnlineParticipantsList: data => dispatch(UpdateOnlineParticipantsList(data)),
    updateOnlineUser: data => dispatch(updateOnlineUser(data)),
    updateFloatingPlayerLink: data => dispatch(FloatingPlayerAdded(data)),
    naviagtionAgneda: data => dispatch(naviagtionAgneda(data)),
    AgendaSessionId: data => dispatch(AgendaSessionId(data)),
    OnlineUser: data => dispatch(OnlineUser(data)),
    allFeatureData: data => dispatch((allFeatureData(data))),
    // RemoveScroll:data=>dispatch(RemoveScroll(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(App)
